import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Category } from '../../../types/category'
import { ScrollAndShow } from '../../../components/scroll-and-show'
import { Layout } from '../../../components/layout'
import { ArticleCard } from '../../../components/article-card'
import { useCategories } from '../../../hooks/useCategories'
import { useStories } from '../../../hooks/useStories'
import { useEventReports } from '../../../hooks/useEventReports'
import { SEO } from '../../../components/seo'

const Page = ({ params }: any) => {
	const { categories } = useCategories()
	const { stories } = useStories()
	const { eventReports } = useEventReports()

	const [category, setCategory] = useState<Category | undefined>(undefined)
	const [articles, setArticles] = useState([])

	useEffect(() => {
		const category = categories.find(category => category.title === params._category)
		setCategory(category)

		const newStories = stories.filter(story => story.categories.find(category => category.title === params._category))
		const newEventReports = eventReports.filter(eventReport => eventReport.categories.find(category => category.title === params._category))
		setArticles([...newStories, ...newEventReports])
	}, [categories, stories, eventReports])

	return (
		<Layout>
			<SEO
				title={`${params._category} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<div className="article">
				<div className="text-center">
					<ScrollAndShow>
						<div className="category font-lato">CATEGORY</div>
					</ScrollAndShow>
					<ScrollAndShow>
						<div className="title-text font-yu">{params._category}</div>
					</ScrollAndShow>
				</div>
				<ScrollAndShow>
					<div className="category-description font-yu">{category && category.description}</div>
				</ScrollAndShow>
				<div className="container">
					{articles.map((article, index) => (
						<ScrollAndShow key={index} delay={100 * index}>
							<Link to={`${article.vol ? 'stories' : 'event_reports'}/${article.title}`}>
								<ArticleCard article={article} />
							</Link>
						</ScrollAndShow>
					))}
				</div>
				<ScrollAndShow>
					<div className="categories-link">
						<Link className="font-yu" to="/categories">カテゴリー一覧へ</Link>
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	)
}

export default Page

